import React from "react";
import PagedTable from "../../../../widgets/tables/paged_table";
import { Link } from "react-router-dom";
import { paths } from "../../../app_modules";
import { useCompetencyGroupPaged } from "../../../../_hooks/competency_group/useCompetencyGroupPaged.hook";
import { changeStateByIdCompetencyGroupEvent } from "../../../../_events/competency-group/create.event";
import { alertError } from "../../../../_utils/alerts";
import { Popconfirm } from "antd";
import { Button, Col, Row } from "react-bootstrap";

function Table(){

    const COUNT = 10;
    const academicConceptPaged = useCompetencyGroupPaged({ page: 0, limit: COUNT });
    const [ currentPage, setCurrentPage ] = React.useState(0);
    const [ filterName, setFilterName ] = React.useState('');

    const changeState = (uuid, state, reload = () => {}) => {
        changeStateByIdCompetencyGroupEvent(uuid, state)
        .then(json => {
            reload();
        })
        .catch(err => alertError(err.message));
    }

    const buildStateBtn = (param) => {
        return(
            <Popconfirm
                title=""
                description={`¿Está seguro de ${param.state === 0 ? 'Activar' : 'Eliminar'}?`}
                onConfirm={() => {
                    changeState(param.uuid, param.state === 0 ? 1 : 0, () => academicConceptPaged.loadData(currentPage, COUNT));
                }}
                onCancel={() => {}}
                okText="Sí"
                cancelText="No"
            >
                <span style={{ marginRigth: '20px', cursor: 'pointer' }}>
                    {
                        param.state === 1 ?
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#e00000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                        :
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#55a829"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#31a533" strokeWidth="1.5"></circle> <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#31a533" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                    }
                </span>
            </Popconfirm>
        );
    }

    const buildForceDeleteBtn = (param) => {
        if(param.state !== 0) return <></>;

        return(
            <Popconfirm
                title=""
                description={`¿Está seguro de eliminar POR COMPLETO?`}
                onConfirm={() => {
                    changeState(param.uuid, -1, () => academicConceptPaged.loadData(currentPage, COUNT));
                }}
                onCancel={() => {}}
                okText="Sí"
                cancelText="No"
            >
                <span style={{ marginRigth: '20px', cursor: 'pointer' }}>
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#e00000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                </span>
            </Popconfirm>
        );
    }

    const columns = [
        {
            key: 'uuid',
            name: 'Código',
            label: true,
        },
        {
            key: 'name',
            name: 'Nombre',
            label: true,
        },
        {
            key: 'concept',
            name: 'Concepto Académico',
            label: false,
            render: (param) => {
                return(
                    <span>{param.concept?.name}</span>
                );
            }
        },
        {
            key: '',
            name: 'Acciones',
            label: false,
            render: (param) => {
                return(
                    <div className="flex-row">
                        {buildStateBtn(param)}
                        <Link to={`${paths.competency_group_form}?id=${param.uuid}`}>
                        <svg width={20} height={20} fill="#eca409" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.936 494.936" stroke="#eca409" strokeWidth="11.878464"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path> <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path> </g> </g> </g></svg>
                        </Link>
                        {buildForceDeleteBtn(param)}
                    </div>
                );
            }
        },
    ];

    const changePage = (page) => {
        setCurrentPage(page);
        academicConceptPaged.loadData(page, COUNT, filterName);
    }

    return(
        <div>
            <div style={{ marginBottom: '10px' }}>
                <Row>
                    <Col lg={3} xs={12}>
                        <input 
                            className='vp-input' 
                            type='text'
                            value={ filterName }
                            onChange={(e) => {
                                setFilterName(e.target.value)
                            }}
                        />
                    </Col>
                    <Col lg={3} xs={12}>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
                        <Button 
                            onClick={ () => {
                                academicConceptPaged.loadData(currentPage, COUNT, filterName);
                            } } 
                            variant="success" size="sm" style={{ width: '100%', marginRight: '6px' }}>
                            Filtrar
                        </Button>
                        <Button 
                            onClick={ () => {
                                setFilterName('');
                                academicConceptPaged.loadData(currentPage, COUNT, '');
                            } } 
                            variant="primary" size="sm" style={{ width: '100%' }}>
                            Limpiar
                        </Button>
                        </div>
                    </Col>
                    <Col lg={6} xs={12}></Col>
                </Row>
            </div>
            <PagedTable
                columns={columns}
                data={academicConceptPaged.data[0]}
                total={academicConceptPaged.data[1]}
                dataPerPage={COUNT}
                currentPage={currentPage}
                onChangePage={(page) => changePage(page)}
            />
        </div>
        
    );
}

export default Table;