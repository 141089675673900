import React from "react";
import { buildEmptyForm, transformEntityForm } from "../../_events/form/model";
import { findFormByIdEvent } from "../../_events/form/find.event";
import { editFormEvent } from "../../_events/form/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";
import { useQuery } from "../../_utils/url_query_hook";

export const useEditForm = (idParam) => {

    const [ entity, setEntity ] = React.useState(buildEmptyForm());
    const query = useQuery();
    const id = query.get("id");
    const edit = () => {
        editFormEvent(entity.uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(id || idParam){
            findFormByIdEvent(id ?? idParam)
            .then(json => {
                if(json.data){
                    setEntity(transformEntityForm(json.data));
                }                
            })
            .catch(err => {
                alertError(err.message);
            });
        }
    }, [id, idParam]);

    return { entity, setEntity, edit };
}