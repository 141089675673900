import { useState } from "react"
import { alertError } from "../../_utils/alerts";

export const useDownloadFile = (downloadEvent) => {

    const [loading, setLoading] = useState(false);

    const downloadFile = (id) => {
        setLoading(true);

        downloadEvent?.(id)
        .then(_ => {
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            alertError(err.message);
        });
    }

    return {
        loading,
        downloadFile
    }
}